import { AUTH } from 'constants/localStorageKeys'
import { call, put } from 'redux-saga/effects'
import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'
import {
	sendVerificationSuccess,
	sendVerificationFailure,
	setVerificationResendAvailable,
} from 'redux/verification/reducer'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'

export function* sendVerificationSaga({ payload }: PayloadAction<string>) {
	try {
		const data: {
			message: string
			access_token?: string
		} = yield call(authMiddleware, {
			url: `${app_config.services.verification}/${payload}`,
			method: 'GET',
		})

		if (data.access_token) {
			localStorage.setItem(AUTH, data.access_token)

			yield put(sendVerificationSuccess())
		} else {
			yield put(setVerificationResendAvailable())
		}

		yield put(
			onOpenNotification({
				message: data.message,
				notificationType: data.access_token ? 'success' : 'error',
			})
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(sendVerificationFailure())
		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
