import { call, put } from 'redux-saga/effects'
import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'
import {
	setVerificationResendSuccess,
	setVerificationResendFailure,
	setEmail,
} from 'redux/verification/reducer'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'

export function* resendVerificationSaga({ payload }: PayloadAction<string>) {
	try {
		const {
			email,
		}: {
			email: string
		} = yield call(authMiddleware, {
			url: `${app_config.services.verification}/${payload}`,
			method: 'GET',
			data: {
				resend: true,
			},
		})

		yield put(setEmail(email))

		yield put(setVerificationResendSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(setVerificationResendFailure())
		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
