import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	getUserSuccess,
	setUserProfileFailure,
	setUserProfileSuccess,
} from 'redux/user/reducer'
import { IUser, IUserProfileFormData } from './types'

export function* setUserProfileSaga({
	payload,
}: PayloadAction<IUserProfileFormData>) {
	try {
		const {
			data,
		}: {
			data: { user: IUser }
			message: string
		} = yield call(apiMiddleware, {
			url: app_config.services.updateUserProfile,
			method: 'POST',
			data: payload,
		})

		yield put(getUserSuccess(data.user))

		yield put(setUserProfileSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(setUserProfileFailure())
	}
}
