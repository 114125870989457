import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAccountState } from './types'

const initialState: IAccountState = {
	walletAccount: null,
	walletProtocol: null,
	walletBalances: null,
}

const walletAccountSlice = createSlice({
	name: 'walletAccount',
	initialState,
	reducers: {
		setWalletAccount: (state, { payload }: PayloadAction<IAccountState>) => {
			if (payload.walletAccount) {
				state.walletAccount = payload.walletAccount
			}

			if (payload.walletProtocol) {
				state.walletProtocol = payload.walletProtocol
			}

			if (payload.walletBalances) {
				state.walletBalances = Object.assign(
					state.walletBalances ?? {},
					payload.walletBalances
				)
			}
		},
		resetWalletAccount: (state) => {
			state.walletAccount = null
			state.walletProtocol = null
			state.walletBalances = null
		},
	},
})

export const { setWalletAccount, resetWalletAccount } =
	walletAccountSlice.actions

export const { reducer } = walletAccountSlice
