import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import { getUserSuccess } from 'redux/user/reducer'
import { IUser } from 'redux/user/types'
import { IJoinUserRequest } from 'redux/userList/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { joinUserFailure, joinUserSuccess } from '../reducer'

export function* joinUserSaga({ payload }: PayloadAction<IJoinUserRequest>) {
	try {
		const {
			data,
		}: {
			data: { user: IUser }
		} = yield call(apiMiddleware, {
			url: app_config.services.joinUsers,
			data: payload,
			method: 'POST',
		})

		yield put(getUserSuccess(data.user))
		yield put(joinUserSuccess())
	} catch (e) {
		const error = e as { message?: string }

		if (error?.message) {
			yield put(
				onOpenNotification({
					message: error.message,
					notificationType: 'error',
				})
			)
		}

		yield put(joinUserFailure())
	}
}
