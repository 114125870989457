/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVerificationState } from './types'

const initialState: IVerificationState = {
	isLoading: true,
	isVerificationRequestSuccess: false,
	isVerificationResendAvailable: false,
	isVerificationResendSuccess: false,
	verificationCode: null,
	email: null,
}

const verificationSlice = createSlice({
	name: 'verification',
	initialState,
	reducers: {
		sendVerificationRequest: (state, payload: PayloadAction<string>) => {
			state.isLoading = true
		},

		resendVerificationRequest: (state, payload: PayloadAction<string>) => {
			state.isLoading = true
			state.isVerificationResendSuccess = false
		},

		sendVerificationSuccess: (state) => {
			state.isLoading = false
			state.isVerificationRequestSuccess = true
		},
		sendVerificationFailure: (state) => {
			state.isLoading = false
			state.isVerificationRequestSuccess = false
		},

		setVerificationResendSuccess: (state) => {
			state.isLoading = false
			state.isVerificationResendSuccess = true
		},
		setVerificationResendFailure: (state) => {
			state.isLoading = false
			state.isVerificationResendSuccess = false
		},

		setVerificationResendAvailable: (state) => {
			state.isLoading = false
			state.isVerificationResendAvailable = true
		},

		setEmail: (state, { payload }: PayloadAction<string>) => {
			state.email = payload
		},
	},
})

export const {
	sendVerificationRequest,
	resendVerificationRequest,
	sendVerificationSuccess,
	sendVerificationFailure,
	setVerificationResendAvailable,
	setVerificationResendSuccess,
	setVerificationResendFailure,
	setEmail,
} = verificationSlice.actions

export const { reducer } = verificationSlice
