import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { IInviteUserRequest } from 'redux/userList/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import { inviteUsersFailure, inviteUsersSuccess } from '../reducer'

export function* inviteUsersSaga({
	payload,
}: PayloadAction<IInviteUserRequest>) {
	try {
		const data: { message: string } = yield call(apiMiddleware, {
			url: app_config.services.inviteUsers,
			data: payload,
			method: 'POST',
		})

		yield put(
			onOpenNotification({
				message: data.message,
				notificationType: 'success',
			})
		)

		yield put(inviteUsersSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(inviteUsersFailure())
	}
}
