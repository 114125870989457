import { call, put, select } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import { IInvoiceState } from 'redux/invoice/types'
import { tagRequestComplete } from 'redux/tag/reducer'
import { IStore } from 'redux/types'

import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import { ITag } from '../types'

export function* updateTagSaga({ payload }: PayloadAction<Pick<ITag, 'id'>>) {
	try {
		const { currentInvoice }: IInvoiceState = yield select(
			(state: IStore) => state.invoice
		)

		const response: {
			tag: ITag
		} = yield call(apiMiddleware, {
			url: app_config.services.updateTag,
			method: 'POST',
			data: {
				...payload,
				invoiceId: currentInvoice.id,
			},
		})

		yield put(tagRequestComplete(response.tag))
	} catch (e) {
		yield put(tagRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
