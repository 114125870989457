import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import {
	setUserWalletAcceptedTokenFailure,
	setUserWalletAcceptedTokenSuccess,
} from 'redux/user/reducer'
import { IUserWalletAcceptedTokensRequest } from './types'

export function* setUserWalletAcceptedTokenSaga({
	payload,
}: PayloadAction<IUserWalletAcceptedTokensRequest>) {
	try {
		const {
			walletAcceptedTokens,
		}: {
			walletAcceptedTokens: WalletAccountBalanceToken[]
		} = yield call(apiMiddleware, {
			url: app_config.services.updateUserWalletAcceptedToken,
			method: 'POST',
			data: { ...payload },
		})

		yield put(setUserWalletAcceptedTokenSuccess(walletAcceptedTokens))
	} catch (e) {
		yield put(setUserWalletAcceptedTokenFailure())
	}
}
