/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITag, ITagState } from './types'

const initialState: ITagState = {
	isTagLoading: false,
}

const invoicesSlice = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		createTagRequest: (
			state,
			{ payload }: PayloadAction<Pick<ITag, 'title'>>
		) => {
			state.isTagLoading = true
			state.currentTag = undefined
		},

		updateTagRequest: (state, { payload }: PayloadAction<Pick<ITag, 'id'>>) => {
			state.isTagLoading = true
			state.currentTag = undefined
		},

		deleteTagRequest: (state, { payload }: PayloadAction<Pick<ITag, 'id'>>) => {
			state.isTagLoading = true
			state.currentTag = undefined
		},

		tagRequestComplete: (
			state,
			{ payload }: PayloadAction<ITag | undefined>
		) => {
			state.isTagLoading = false
			state.currentTag = payload
		},

		resetTag: (state) => {
			state.isTagLoading = false
			state.currentTag = undefined
		},
	},
})

export const {
	createTagRequest,
	updateTagRequest,
	deleteTagRequest,
	tagRequestComplete,
	resetTag,
} = invoicesSlice.actions

export const { reducer } = invoicesSlice
