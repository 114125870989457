import { call, put } from 'redux-saga/effects'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	setDiscardWelcomeStepsFailure,
	setDiscardWelcomeStepsSuccess,
} from 'redux/user/reducer'

export function* discardWelcomeStepsSaga() {
	try {
		yield call(apiMiddleware, {
			url: app_config.services.discardWelcomeSteps,
			method: 'POST',
		})

		yield put(setDiscardWelcomeStepsSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(setDiscardWelcomeStepsFailure())
	}
}
