import { IInvoice, ProtocolEnumType, WalletEnumType } from 'redux/invoice/types'
import { UserWalletInterface } from 'redux/wallet/types';
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'

export enum RoleEnumType {
	COMPANY = 'company',
	CONTRACTOR = 'contractor',
	MEMBER = 'member',
}

export enum SubscriptionEnumType {
	ESCROW = 'escrow',
	SOLO = 'solo',
	TEAM = 'team',
}

export enum PromoCampaignEnumType {
	NO_CAMPAIGN = '',
}

export interface IUserProfileFormData {
	name: string
	surname: string
	email: string
	address: string
	role: string
}

export interface IUserProtocol {
	protocol: ProtocolEnumType
}

export interface IUser {
	avatar?: string
	account_id: string
	created_at: string
	email: string
	address: string
	surname: string
	id: string
	name: string
	role: string
	protocol: ProtocolEnumType
	uid: string
	updated_at: string
	is_welcome_discarded: boolean
	otp_verified: boolean
	otp_enabled: boolean
	invoices: IInvoice[]
	team: ITeam | null
	teams?: ITeam[]
}

export interface ITeamRoleUser extends IUser {
	team_role_id: string
	team: ITeamRoleTeam
}

export interface IUserState {
	isLoadingUser: boolean
	isWalletAcceptedTokensLoading: boolean
	isSetUserTeamProfileLoading: boolean
	isCreateUserTeamLoading: boolean
	isCreateUserTeamSuccess: boolean
	isSetUserTeamSubscriptionLoading: boolean
	isSetUserTeamApprovalSettingsLoading: boolean
	isSetUserTeamApprovalSettingsSuccess: boolean
	isSetUserTeamDefaultApproversLoading: boolean
	isDeleteUserAvatarLoading: boolean
	isUploadUserAvatarLoading: boolean
	isUploadUserAvatarSuccess: boolean,
	isSetUserProtocolLoading: boolean
	isSetUserAccountLoading: boolean
	isSetUserAccountSuccess: boolean
	isDeleteUserAvatarSuccess: boolean
	isSetUserWalletAcceptedTokenSuccess: boolean
	isSetUserWalletAcceptedTokenLoading: boolean
	isSetUserProfileSuccess: boolean
	isSetUserProfileLoading: boolean
	isSetUserTeamSuccess: boolean
	isSetUserTeamLoading: boolean
	isSetUserProtocolSuccess: boolean
	isDiscardWelcomeStepsLoading: boolean
	isResendUserInviteLoading: boolean
	isRevokeUserInviteLoading: boolean
	isRevokeUserInviteSuccess: boolean
	isCompanyProfileModalOpened: boolean
	isWalletModalModalOpened: boolean
	isUserWalletsLoading: boolean
	userWallets: IUserWallet[]
	userWalletAcceptedTokens: WalletAccountBalanceToken[]
	userTeamRoles: TeamRoleEnumType[]
	userTeams: ITeam[]
	userTeamDefaultApprovers: ITeamRoleUser[]
	user: IUser
}

export enum TeamRoleEnumType {
	TEAM_CONTRACTOR = 'contractor',
	TEAM_OWNER = 'owner',
	TEAM_ADMIN = 'admin',
	TEAM_APPROVER = 'approver',
	TEAM_MEMBER = 'member',
}

export interface ITeam {
	id: string
	logo?: string
	name?: string
	address?: string
	representative?: string
	owner?: Pick<IUser, 'id' | 'email' | 'name' | 'surname'>
	roles?: TeamRoleEnumType[]
	approvalsRequired?: string
	approvalThreshold?: string
	subscription?: SubscriptionEnumType
	contract_fee_percent?: string
	contract_type?: string
}

export interface ITeamRoleTeam extends ITeam {
	roles: TeamRoleEnumType[]
}

export enum WalletTransactionRoleEnumType {
	WALLET_TRANSACTION_ROLE_BOTH = 'both',
	WALLET_TRANSACTION_ROLE_SENDER = 'send',
	WALLET_TRANSACTION_ROLE_RECEIVER = 'receive',
}

export interface IUserWallet extends UserWalletInterface {
	accountId: string
	protocol: ProtocolEnumType
	wallet: WalletEnumType
	type?: WalletTransactionRoleEnumType
	isUpdateWalletDataLoading?: boolean
	isWalletUpdatedByActions?: boolean
}

export interface IUserWalletAcceptedTokensRequest {
	accountId: string
	token?: WalletAccountBalanceToken
}

export interface IResendInvitePayload {
	email: string
}

export type IRevokeInvitePayload = IResendInvitePayload

export interface IInviteProps {
	inviteCode?: string
	token?: WalletAccountBalanceToken
	protocol?: ProtocolEnumType
	role?: RoleEnumType
}

export interface IUserTeamApprovalSettingsPayload {
	approvers: string[]
	approvalThreshold: string
	approvalsRequired: string | number
}

export interface IUpdateUserTeam {
	id: string
}

export interface ICompanyProfileModal {
	isOpened: boolean
}
