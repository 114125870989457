import { PayloadAction } from '@reduxjs/toolkit';
import app_config from 'config/app_config';
import { call, put, select } from 'redux-saga/effects';
import { onOpenNotification } from 'redux/notification/reducer';
import { IStore } from 'redux/types';
import {
	getWalletByAccIdFailure,
	getWalletByAccIdSuccess,
	updateWalletDataSuccess,
	updateWalletDataFailure,
	deleteWalletDataSuccess,
	deleteWalletDataFailure,
	IUpdateWalletProps
} from 'redux/wallet/reducer';
import { resetWalletAccount } from 'redux/walletAccount/reducer';
import apiMiddleware from 'services/api_middleware';
import { Wallet } from 'utils/nearWallet';

export function* getWalletByAccIdSaga({ payload }: PayloadAction<string>) {
	try {
		const {
			wallet,
		} = yield call(apiMiddleware, {
			url: `${app_config.services.getWalletById}${payload}`
		})

		yield put(getWalletByAccIdSuccess(wallet))
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(getWalletByAccIdFailure())
	}
}

export function* updateWalletDataSaga({ payload }: PayloadAction<IUpdateWalletProps>) {
	try {
		const {
			message,
			wallet
		} = yield call(apiMiddleware, {
			url: `${app_config.services.updateWallet}${payload.id}`,
			method: 'POST',
			data: payload
		})

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)

		yield put(updateWalletDataSuccess(wallet))
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(updateWalletDataFailure())
	}
}

export function* deleteWalletDataSaga({ payload }: PayloadAction<string>) {
	try {
		const {
			message,
		} = yield call(apiMiddleware, {
			url: `${app_config.services.deleteWallet}${payload}`,
			method: 'POST',
		})

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)

		yield put(deleteWalletDataSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(deleteWalletDataFailure())
	}
}
