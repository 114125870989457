import { ProtocolEnumType } from 'redux/invoice/types'

export enum WalletAccountBalanceToken {
	NOT_DEFINED = '',
	NEAR_USDTT = 'usdt.tether-token.near',
	NEAR_USDTT_TESTNET = 'usdtt.fakes.testnet',
	NEAR_USDT = 'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near',
	NEAR_USDT_TESTNET = 'usdt.fakes.testnet',
	NEAR_USDC = 'a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near',
	NEAR_USDC_TESTNET = 'usdc.fakes.testnet',
	WRAP_NEAR_TESTNET = 'wrap.testnet',
	WRAP_NEAR = 'wrap.near',
	USD_NEAR = 'usd-near',
	WRAP_ETH_SEPOLIA = '0xc3A31906AFAbd531A9481737025A5e1Ef77497DD',
	WRAP_ETH = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
	ETH_USDC_SEPOLIA = '0xE18C8033Ac659863d73BAb92FdD3B75e8B4aef1f',
	ETH_USDC = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
	ETH_USDT_SEPOLIA = '0x39d5942A3c506B1B756c9D899aF073de1Ca8D44d',
	ETH_USDT = '0xdAC17F958D2ee523a2206206994597C13D831ec7',
	ETH_BUSD_SEPOLIA = '0x2Bf15E6806AE623D1B1e6d52aC2A7A1B22341565',
	ETH_BUSD = '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
	ETH_GUSD_SEPOLIA = '0x66e282ae3bf2c46690989F70dB6615ff5Ed2A367',
	ETH_GUSD = '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
	WRAP_TRON_NILE = 'TB2RjRskzvicg3MxDUAisZgNjNAFXBK27J',
	WRAP_TRON = 'TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR',
	TRON_USDT_NILE = 'TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj',
	TRON_USDT = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
	BSC_USDT = '0x55d398326f99059fF775485246999027B3197955',
	BSC_USDT_TESTNET = '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
	BSC_USDC = '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
	BSC_USDC_TESTNET = '0x64544969ed7EBf5f083679233325356EbE738930',
	BSC_BUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56',
	BSC_BUSD_TESTNET = '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
	POLYGON_USDT = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
	POLYGON_USDT_TESTNET = '0x3813e82e6f7098b9583FC0F33a962D02018B6803',
	POLYGON_USDC = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	POLYGON_USDC_TESTNET = '0xE097d6B3100777DC31B34dC2c58fB524C2e76921',
	POLYGON_BUSD = '0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7',
	POLYGON_BUSD_TESTNET = '0x74960A82548E5DB184265A35D3Bc822b353D10D9',

	NEAR = 'near.near',
	ETH = 'ETH',
	TRON = 'TRX',
	BSC_BNB = 'BNB',
	POLYGON_MATIC = 'MATIC',
}

export enum TokenSymbols {
	NOT_DEFINED = '',
	NEAR_USDT = 'USDT.e',
	NEAR_USDTT = 'USDT',
	NEAR_USDC = 'USDC.e',
	USDC = 'USDC',
	USDT = 'USDT',
	BUSD = 'BUSD',
	GUSD = 'GUSD',
	WRAPPED_NEAR = 'wNEAR',
	WRAPPED_ETH = 'wETH',
	WRAPPED_TRON = 'wTRX',
	USD = 'USD',
	NEAR = 'NEAR',
	ETH = 'ETH',
	BNB = 'BNB',
	MATIC = 'MATIC',
	TRON = 'TRX',
}

export type WalletAccountBalances = {
	[key in WalletAccountBalanceToken]?: string | null
}

export interface IAccountState {
	walletAccount?: string | null
	walletProtocol?: ProtocolEnumType | null
	walletBalances?: WalletAccountBalances | null
}
