/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProtocolEnumType } from 'redux/invoice/types'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import {
	ICompanyProfileModal,
	IResendInvitePayload,
	IRevokeInvitePayload,
	ITeam, ITeamRoleUser, IUpdateUserTeam,
	IUser,
	IUserProfileFormData,
	IUserState, IUserTeamApprovalSettingsPayload,
	IUserWallet,
	IUserWalletAcceptedTokensRequest,
	RoleEnumType, SubscriptionEnumType, TeamRoleEnumType,
} from './types'

const initialState: IUserState = {
	isLoadingUser: false,
	isWalletAcceptedTokensLoading: false,
	isSetUserTeamProfileLoading: false,
	isCreateUserTeamLoading: false,
	isCreateUserTeamSuccess: false,
	isSetUserTeamSubscriptionLoading: false,
	isSetUserTeamApprovalSettingsLoading: false,
	isSetUserTeamApprovalSettingsSuccess: false,
	isSetUserTeamDefaultApproversLoading: false,
	isSetUserProfileLoading: false,
	isSetUserProfileSuccess: false,
	isSetUserTeamLoading: false,
	isSetUserTeamSuccess: false,
	isDeleteUserAvatarLoading: false,
	isDeleteUserAvatarSuccess: false,
	isUploadUserAvatarLoading: false,
	isUploadUserAvatarSuccess: false,
	isSetUserAccountLoading: false,
	isSetUserAccountSuccess: false,
	isSetUserWalletAcceptedTokenSuccess: false,
	isSetUserWalletAcceptedTokenLoading: false,
	isDiscardWelcomeStepsLoading: false,
	isSetUserProtocolLoading: false,
	isSetUserProtocolSuccess: false,
	isResendUserInviteLoading: false,
	isRevokeUserInviteLoading: false,
	isRevokeUserInviteSuccess: false,
	isCompanyProfileModalOpened: false,
	isWalletModalModalOpened: false,
	isUserWalletsLoading: false,

	userWallets: [],
	userWalletAcceptedTokens: [],
	userTeamRoles: [],
	userTeams: [],
	userTeamDefaultApprovers: [],
	user: {
		account_id: '',
		created_at: '',
		email: '',
		address: '',
		surname: '',
		id: '',
		name: '',
		role: RoleEnumType.COMPANY,
		protocol: ProtocolEnumType.NOT_DEFINED,
		uid: '',
		updated_at: '',
		otp_verified: false,
		otp_enabled: false,
		is_welcome_discarded: false,
		invoices: [],
		team: {
			id: '',
			name: '',
			address: '',
			representative: '',
			subscription: SubscriptionEnumType.ESCROW,
			owner: {
				email: '',
				surname: '',
				id: '',
				name: '',
			},
			roles: [],
			approvalsRequired: '0',
			approvalThreshold: '0',
		},
	},
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getUserRequest: (state) => {
			state.isLoadingUser = true
		},
		getUserSuccess: (state, { payload }: PayloadAction<IUser>) => {
			state.user = payload

			if (payload?.team && payload.team?.roles) {
				state.userTeamRoles = payload.team.roles
			}

			if (payload?.teams) {
				state.userTeams = payload.teams
			}

			state.isLoadingUser = false
		},
		getUserFailure: (state) => {
			state.isLoadingUser = false
		},

		setUserTeamRoles: (state, { payload }: PayloadAction<TeamRoleEnumType[]>) => {
			state.userTeamRoles = payload
		},

		getUserWalletAcceptedTokensRequest: (
			state,
			{ payload }: PayloadAction<IUserWalletAcceptedTokensRequest>
		) => {
			state.isWalletAcceptedTokensLoading = true
		},
		getUserWalletAcceptedTokensSuccess: (
			state,
			{ payload }: PayloadAction<WalletAccountBalanceToken[]>
		) => {
			state.isWalletAcceptedTokensLoading = false

			state.userWalletAcceptedTokens = payload
		},
		getUserWalletAcceptedTokensFailure: (state) => {
			state.isWalletAcceptedTokensLoading = false

			state.userWalletAcceptedTokens = []
		},

		setUserTeamProfileRequest: (state, { payload }: PayloadAction<ITeam>) => {
			state.isSetUserTeamProfileLoading = true
		},
		setUserTeamProfileSuccess: (state) => {
			state.isSetUserTeamProfileLoading = false
		},
		setUserTeamProfileFailure: (state) => {
			state.isSetUserTeamProfileLoading = false
		},

		createUserTeamRequest: (state, { payload }: PayloadAction<ITeam>) => {
			state.isCreateUserTeamLoading = true
			state.isCreateUserTeamSuccess = false
		},
		createUserTeamSuccess: (state) => {
			state.isCreateUserTeamLoading = false
			state.isCreateUserTeamSuccess = true
		},
		createUserTeamFailure: (state) => {
			state.isCreateUserTeamLoading = false
			state.isCreateUserTeamSuccess = false
		},

		setUserTeamSubscriptionRequest: (state, { payload }: PayloadAction<{ subscription: SubscriptionEnumType }>) => {
			state.isSetUserTeamSubscriptionLoading = true
		},
		setUserTeamSubscriptionSuccess: (state) => {
			state.isSetUserTeamSubscriptionLoading = false
		},
		setUserTeamSubscriptionFailure: (state) => {
			state.isSetUserTeamSubscriptionLoading = false
		},

		setUserTeamApprovalSettingsRequest: (state, { payload }: PayloadAction<IUserTeamApprovalSettingsPayload>) => {
			state.isSetUserTeamApprovalSettingsLoading = true
			state.isSetUserTeamApprovalSettingsSuccess = false
		},
		setUserTeamApprovalSettingsSuccess: (state) => {
			state.isSetUserTeamApprovalSettingsLoading = false
			state.isSetUserTeamApprovalSettingsSuccess = true
		},
		setUserTeamApprovalSettingsFailure: (state) => {
			state.isSetUserTeamApprovalSettingsLoading = false
			state.isSetUserTeamApprovalSettingsSuccess = false
		},

		getUserTeamDefaultApproversRequest: (state) => {
			state.isSetUserTeamDefaultApproversLoading = true
		},
		getUserTeamDefaultApproversSuccess: (state, { payload }: PayloadAction<ITeamRoleUser[]>) => {
			state.userTeamDefaultApprovers = payload
			state.isSetUserTeamDefaultApproversLoading = false
		},
		getUserTeamDefaultApproversFailure: (state) => {
			state.isSetUserTeamDefaultApproversLoading = false
		},

		setUserAccountRequest: (state, { payload }: PayloadAction<IUserWallet>) => {
			state.isSetUserAccountLoading = true
			state.isSetUserAccountSuccess = false
		},
		setUserAccountSuccess: (state) => {
			state.isSetUserAccountLoading = false
			state.isSetUserAccountSuccess = true
		},
		setUserAccountFailure: (state) => {
			state.isSetUserAccountLoading = false
			state.isSetUserAccountSuccess = false
		},

		setUserWalletAcceptedTokenRequest: (
			state,
			{ payload }: PayloadAction<IUserWalletAcceptedTokensRequest>
		) => {
			state.isSetUserWalletAcceptedTokenLoading = true
			state.isSetUserWalletAcceptedTokenSuccess = false
		},
		setUserWalletAcceptedTokenSuccess: (
			state,
			{ payload }: PayloadAction<WalletAccountBalanceToken[]>
		) => {
			state.isSetUserWalletAcceptedTokenLoading = false
			state.isSetUserWalletAcceptedTokenSuccess = true

			state.userWalletAcceptedTokens = payload
		},
		setUserWalletAcceptedTokenFailure: (state) => {
			state.isSetUserWalletAcceptedTokenLoading = false
			state.isSetUserWalletAcceptedTokenSuccess = false

			state.userWalletAcceptedTokens = []
		},

		setUserProfileRequest: (
			state,
			{ payload }: PayloadAction<IUserProfileFormData>
		) => {
			state.isSetUserProfileLoading = true
			state.isSetUserProfileSuccess = false
		},
		setUserProfileSuccess: (state) => {
			state.isSetUserProfileLoading = false
			state.isSetUserProfileSuccess = true
		},
		setUserProfileFailure: (state) => {
			state.isSetUserProfileLoading = false
			state.isSetUserProfileSuccess = false
		},

		setUserTeamRequest: (
			state,
			{ payload }: PayloadAction<IUpdateUserTeam>
		) => {
			state.isSetUserTeamLoading = true
			state.isSetUserTeamSuccess = false
		},
		setUserTeamSuccess: (state) => {
			state.isSetUserTeamLoading = false
			state.isSetUserTeamSuccess = true
		},
		setUserTeamFailure: (state) => {
			state.isSetUserTeamLoading = false
			state.isSetUserTeamSuccess = false
		},

		deleteUserAvatarRequest: (state, { payload }: PayloadAction<{ isTeam: boolean }>) => {
			state.isDeleteUserAvatarLoading = true
			state.isDeleteUserAvatarSuccess = false
		},
		deleteUserAvatarSuccess: (state) => {
			state.isDeleteUserAvatarLoading = false
			state.isDeleteUserAvatarSuccess = true
		},
		deleteUserAvatarFailure: (state) => {
			state.isDeleteUserAvatarLoading = false
			state.isDeleteUserAvatarSuccess = false
		},

		uploadUserAvatarRequest: (state, { payload }: PayloadAction<{ file: File, isTeam: boolean }>) => {
			state.isUploadUserAvatarLoading = true
			state.isUploadUserAvatarSuccess = false
		},
		uploadUserAvatarSuccess: (state) => {
			state.isUploadUserAvatarLoading = false
			state.isUploadUserAvatarSuccess = true
		},
		uploadUserAvatarFailure: (state) => {
			state.isUploadUserAvatarLoading = false
			state.isUploadUserAvatarSuccess = false
		},

		discardWelcomeStepsRequest: (state) => {
			state.isDiscardWelcomeStepsLoading = true
		},
		setDiscardWelcomeStepsSuccess: (state) => {
			state.isDiscardWelcomeStepsLoading = false
		},
		setDiscardWelcomeStepsFailure: (state) => {
			state.isDiscardWelcomeStepsLoading = false
		},

		discardUserWelcomeSteps: (state) => {
			state.user.is_welcome_discarded = true
		},

		resendUserInviteRequest: (
			state,
			{ payload }: PayloadAction<IResendInvitePayload>
		) => {
			state.isResendUserInviteLoading = true
		},
		setResendUserInviteSuccess: (state) => {
			state.isResendUserInviteLoading = false
		},
		setResendUserInviteFailure: (state) => {
			state.isResendUserInviteLoading = false
		},

		revokeUserInviteRequest: (
			state,
			{ payload }: PayloadAction<IRevokeInvitePayload>
		) => {
			state.isRevokeUserInviteLoading = true
			state.isRevokeUserInviteSuccess = false
		},
		setRevokeUserInviteSuccess: (state) => {
			state.isRevokeUserInviteLoading = false
			state.isRevokeUserInviteSuccess = true
		},
		setRevokeUserInviteFailure: (state) => {
			state.isRevokeUserInviteLoading = false
			state.isRevokeUserInviteSuccess = false
		},

		setCompanyProfileModalOpened: (
			state,
			{ payload: { isOpened } }: PayloadAction<ICompanyProfileModal>
		) => {
			state.isCompanyProfileModalOpened = isOpened
		},

		setWalletModalOpened: (
			state,
			{ payload: { isOpened } }: PayloadAction<ICompanyProfileModal>
		) => {
			state.isWalletModalModalOpened = isOpened
		},

		getUserWalletsRequest: (
			state,
		) => {
			state.isUserWalletsLoading = true
		},
		getUserWalletsSuccess: (
			state,
			{ payload }: PayloadAction<IUserWallet[]>
		) => {
			state.isUserWalletsLoading = false

			state.userWallets = payload
		},
		getUserWalletsFailure: (state) => {
			state.isUserWalletsLoading = false

			state.userWallets = []
		},
	},
})

export const {
	getUserRequest,
	getUserSuccess,
	getUserFailure,

	setUserTeamRoles,

	getUserWalletAcceptedTokensRequest,
	getUserWalletAcceptedTokensSuccess,
	getUserWalletAcceptedTokensFailure,

	setUserTeamProfileRequest,
	setUserTeamProfileSuccess,
	setUserTeamProfileFailure,

	createUserTeamRequest,
	createUserTeamSuccess,
	createUserTeamFailure,

	setUserTeamSubscriptionRequest,
	setUserTeamSubscriptionSuccess,
	setUserTeamSubscriptionFailure,

	setUserTeamApprovalSettingsRequest,
	setUserTeamApprovalSettingsSuccess,
	setUserTeamApprovalSettingsFailure,

	getUserTeamDefaultApproversRequest,
	getUserTeamDefaultApproversSuccess,
	getUserTeamDefaultApproversFailure,

	setUserAccountRequest,
	setUserAccountSuccess,
	setUserAccountFailure,

	setUserWalletAcceptedTokenRequest,
	setUserWalletAcceptedTokenSuccess,
	setUserWalletAcceptedTokenFailure,

	setUserProfileRequest,
	setUserProfileSuccess,
	setUserProfileFailure,

	setUserTeamRequest,
	setUserTeamSuccess,
	setUserTeamFailure,

	deleteUserAvatarRequest,
	deleteUserAvatarSuccess,
	deleteUserAvatarFailure,

	uploadUserAvatarRequest,
	uploadUserAvatarSuccess,
	uploadUserAvatarFailure,

	discardWelcomeStepsRequest,
	setDiscardWelcomeStepsSuccess,
	setDiscardWelcomeStepsFailure,

	discardUserWelcomeSteps,

	resendUserInviteRequest,
	setResendUserInviteSuccess,
	setResendUserInviteFailure,

	revokeUserInviteRequest,
	setRevokeUserInviteSuccess,
	setRevokeUserInviteFailure,

	setCompanyProfileModalOpened,
	setWalletModalOpened,

	getUserWalletsRequest,
	getUserWalletsSuccess,
	getUserWalletsFailure,
} = userSlice.actions

export const { reducer } = userSlice
