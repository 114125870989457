import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDraftValue } from 'pages/settings/components/wallet-management/wallet-details';
import { ProtocolEnumType, WalletEnumType } from 'redux/invoice/types';
import { IUserWallet, WalletTransactionRoleEnumType } from 'redux/user/types';

const initialState: IUserWallet = {
	id: '',
	accountId: '',
	name: '',
	active: false,
	created: undefined,
	updated: undefined,
	protocol: ProtocolEnumType.NOT_DEFINED,
	type: WalletTransactionRoleEnumType.WALLET_TRANSACTION_ROLE_BOTH,
	wallet: WalletEnumType.UNKNOWN_WALLET,
	isUpdateWalletDataLoading: false,
	isWalletUpdatedByActions: false,
}

export interface IUpdateWalletProps extends IDraftValue {
	id?: string
}

const walletSlice = createSlice({
	name: 'wallet',
	initialState: { ...initialState },
	reducers: {
		getWalletByAccIdRequest: (state, { payload }: PayloadAction<string>) => ({
			...state,
			payload
		}),
		getWalletByAccIdSuccess: (state, { payload }) => ({ ...state, ...payload }),
		getWalletByAccIdFailure: () => initialState,
		updateWalletDataRequest: (state, { payload }: PayloadAction<IUpdateWalletProps>) => ({
			...state,
			...payload,
			isUpdateWalletDataLoading: true,
			isWalletUpdatedByActions: false
		}),
		updateWalletDataSuccess: (state, { payload }) => ({
			...state,
			...payload,
			isUpdateWalletDataLoading: false,
			isWalletUpdatedByActions: true
		}),
		updateWalletDataFailure: (state) => ({
			...state,
			isUpdateWalletDataLoading: false,
			isWalletUpdatedByActions: false
		}),
		deleteWalletDataRequest: (state, payload) => ({
			...state,
			isWalletUpdatedByActions: false
		}),
		deleteWalletDataSuccess: (state) => ({
			...state,
			isWalletUpdatedByActions: true
		}),
		deleteWalletDataFailure: (state) => ({
			...state,
			isWalletUpdatedByActions: false
		}),
	}
})

export const {
	getWalletByAccIdSuccess,
	getWalletByAccIdRequest,
	getWalletByAccIdFailure,
	updateWalletDataRequest,
	updateWalletDataSuccess,
	updateWalletDataFailure,
	deleteWalletDataSuccess,
	deleteWalletDataFailure,
	deleteWalletDataRequest
} = walletSlice.actions;
export const { reducer } = walletSlice;
