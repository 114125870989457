import { PayloadAction } from '@reduxjs/toolkit'
import { call, put } from 'redux-saga/effects'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	getUserSuccess,
	getUserFailure,
	getUserWalletAcceptedTokensSuccess,
	getUserWalletAcceptedTokensFailure,
	getUserTeamDefaultApproversSuccess,
	getUserTeamDefaultApproversFailure,
	getUserWalletsSuccess,
	getUserWalletsFailure,
} from 'redux/user/reducer'
import {
	ITeamRoleUser,
	IUser,
	IUserWallet,
	IUserWalletAcceptedTokensRequest,
} from './types'

export function* getUserSaga() {
	try {
		const {
			data,
		}: {
			data: { user: IUser }
		} = yield call(apiMiddleware, {
			url: app_config.services.getUser,
		})

		yield put(getUserSuccess(data.user))
	} catch (e) {
		yield put(getUserFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}

export function* getUserTeamDefaultApproversSaga() {
	try {
		const {
			approvers,
		}: {
			approvers: ITeamRoleUser[]
		} = yield call(apiMiddleware, {
			url: app_config.services.getTeamDefaultApprovers,
		})

		yield put(getUserTeamDefaultApproversSuccess(approvers))
	} catch (e) {
		yield put(getUserTeamDefaultApproversFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}

export function* getUserWalletAcceptedTokensSaga({
	payload,
}: PayloadAction<IUserWalletAcceptedTokensRequest>) {
	try {
		const {
			walletAcceptedTokens,
		}: {
			walletAcceptedTokens: WalletAccountBalanceToken[]
		} = yield call(apiMiddleware, {
			url: app_config.services.getUserWalletAcceptedTokens,
			method: 'POST',
			data: { ...payload },
		})

		yield put(getUserWalletAcceptedTokensSuccess(walletAcceptedTokens))
	} catch (e) {
		yield put(getUserWalletAcceptedTokensFailure())
	}
}

export function* getUserWalletsSaga() {
	try {
		const wallets: IUserWallet[] = yield call(apiMiddleware, {
			url: app_config.services.getWallets,
		})

		yield put(getUserWalletsSuccess(wallets))
	} catch (e) {
		yield put(getUserWalletsFailure())
	}
}
