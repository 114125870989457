import { PayloadAction } from '@reduxjs/toolkit'
import { call, put } from 'redux-saga/effects'
import { IResendInvitePayload } from 'redux/user/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	setResendUserInviteFailure,
	setResendUserInviteSuccess,
} from 'redux/user/reducer'

export function* resendUserInviteSaga({
	payload,
}: PayloadAction<IResendInvitePayload>) {
	try {
		const {
			message,
		}: {
			message: string
		} = yield call(apiMiddleware, {
			url: app_config.services.resendUserInvite,
			method: 'POST',
			data: payload,
		})

		yield put(setResendUserInviteSuccess())

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(setResendUserInviteFailure())
	}
}
