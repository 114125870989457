/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISingleSignOnParams, ISingleSignOnState } from './types'

const initialState: ISingleSignOnState = {
	ssoLoading: false,
	ssoSuccess: false,
}

const singleSignOnSlice = createSlice({
	name: 'singleSignOn',
	initialState,
	reducers: {
		sendSingleSignOnDataRequest: (
			state,
			{ payload }: PayloadAction<ISingleSignOnParams>
		) => {
			state.ssoLoading = true
			state.ssoSuccess = false
		},
		sendSingleSignOnDataSuccess: (state) => {
			state.ssoLoading = false
			state.ssoSuccess = true
		},
		sendSingleSignOnDataFailure: (state) => {
			state.ssoLoading = false
			state.ssoSuccess = false
		},
	},
})

export const {
	sendSingleSignOnDataRequest,
	sendSingleSignOnDataSuccess,
	sendSingleSignOnDataFailure,
} = singleSignOnSlice.actions

export const { reducer } = singleSignOnSlice
