import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import { IWithdrawPayload } from './types'
import { resetWithdraw, sendWithdrawSuccess } from './reducer'

export function* sendWithdrawSaga({
	payload: { invoiceId },
}: PayloadAction<IWithdrawPayload>) {
	try {
		yield call(apiMiddleware, {
			url: app_config.services.withdraw,
			method: 'POST',
			data: { invoiceId },
		})

		yield put(sendWithdrawSuccess({ invoiceId }))
	} catch (e) {
		yield put(resetWithdraw())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
