/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IWithdrawPayload, IWithdrawState } from './types'

const initialState: IWithdrawState = {
	isLoadingWithdraw: false,
	isWithdrawReady: false,
	invoiceId: undefined,
}

const withdrawSlice = createSlice({
	name: 'withdraw',
	initialState,
	reducers: {
		sendWithdrawRequest: (
			state,
			{ payload: { invoiceId } }: PayloadAction<IWithdrawPayload>
		) => {
			state.isLoadingWithdraw = true
			state.isWithdrawReady = false
			state.invoiceId = invoiceId
		},
		sendWithdrawSuccess: (
			state,
			{ payload: { invoiceId } }: PayloadAction<IWithdrawPayload>
		) => {
			state.isLoadingWithdraw = false
			state.isWithdrawReady = true
			state.invoiceId = invoiceId
		},
		resetWithdraw: (state) => {
			state.isLoadingWithdraw = false
			state.isWithdrawReady = false
			state.invoiceId = undefined
		},
	},
})

export const { sendWithdrawRequest, sendWithdrawSuccess, resetWithdraw } =
	withdrawSlice.actions

export const { reducer } = withdrawSlice
