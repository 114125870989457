import { AUTH } from 'constants/localStorageKeys'
import { call, put } from 'redux-saga/effects'
import {
	setAuthFormProvider,
	setAuthFormSSOUserId,
	setIsOtpRequired,
} from 'redux/authForm/reducer'
import { ISingleSignOnParams } from 'redux/singleSignOn/types'
import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'

import {
	sendSingleSignOnDataFailure,
	sendSingleSignOnDataSuccess,
} from './reducer'

export function* sendSingleSignOnDataSaga({
	payload,
}: PayloadAction<ISingleSignOnParams>) {
	try {
		const response: {
			access_token?: string
			message: string
			sso_user_id?: string
			provider?: string
			is_otp_required: boolean
		} = yield call(authMiddleware, {
			url: app_config.services.sso,
			data: payload,
		})

		yield put(setIsOtpRequired({ isOtpRequired: response.is_otp_required }))
		yield put(sendSingleSignOnDataSuccess())

		if (!response.is_otp_required && response.access_token) {
			localStorage.setItem(AUTH, response.access_token)

			if (response.message) {
				yield put(
					onOpenNotification({
						message: response.message,
						notificationType: 'success',
					})
				)
			}
		} else {
			yield put(
				setAuthFormProvider({
					provider: response.provider,
				})
			)
			yield put(
				setAuthFormSSOUserId({
					ssoUserId: response.sso_user_id,
				})
			)
			yield put(
				onOpenNotification({
					message: response.message,
					notificationType: 'info',
				})
			)
		}
	} catch (e) {
		yield put(sendSingleSignOnDataFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
