import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import {
	getUserSuccess,
	setUserAccountFailure,
	setUserAccountSuccess,
} from 'redux/user/reducer'
import { IUser, IUserWallet } from './types'

export function* setUserAccountSaga({ payload }: PayloadAction<IUserWallet>) {
	try {
		const {
			data,
		}: {
			data: { user: IUser }
		} = yield call(apiMiddleware, {
			url: app_config.services.updateUserAccountId,
			method: 'POST',
			data: { ...payload },
		})

		yield put(getUserSuccess(data.user))
		yield put(setUserAccountSuccess())
		yield put(
			onOpenNotification({
				message: `Wallet for ${data.user.protocol?.toUpperCase()} protocol connected`,
				notificationType: 'info',
			})
		)
	} catch (e) {
		yield put(setUserAccountFailure())
	}
}
