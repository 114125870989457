import { PayloadAction } from '@reduxjs/toolkit'
import { call, put } from 'redux-saga/effects'
import { IRevokeInvitePayload } from 'redux/user/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	setRevokeUserInviteFailure,
	setRevokeUserInviteSuccess,
} from 'redux/user/reducer'

export function* revokeUserInviteSaga({
	payload,
}: PayloadAction<IRevokeInvitePayload>) {
	try {
		const {
			message,
		}: {
			message: string
		} = yield call(apiMiddleware, {
			url: app_config.services.revokeUserInvite,
			method: 'POST',
			data: payload,
		})

		yield put(setRevokeUserInviteSuccess())

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(setRevokeUserInviteFailure())
	}
}
