import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	getUserSuccess,
	setUserTeamProfileFailure,
	setUserTeamProfileSuccess,
} from 'redux/user/reducer'
import { IUser, ITeam } from './types'

export function* setUserTeamProfile({ payload }: PayloadAction<ITeam>) {
	try {
		const {
			data,
			message,
		}: {
			data: { user: IUser }
			message: string
		} = yield call(apiMiddleware, {
			url: app_config.services.updateTeamProfile,
			method: 'POST',
			data: { info: payload },
		})

		yield put(getUserSuccess(data.user))
		yield put(setUserTeamProfileSuccess())

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(setUserTeamProfileFailure())
	}
}
