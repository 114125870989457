/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITeamRoleUser } from 'redux/user/types'
import {
	IInviteUserModal,
	IInviteUserRequest,
	IJoinUserRequest,
	IUserListState,
} from './types'

const initialState: IUserListState = {
	userList: [],
	isLoading: false,
	isInviteUserModalOpened: false,
	isInviteUserLoading: false,
	isInviteUserSuccess: false,
	isJoinUserLoading: false,
	isJoinUserSuccess: false,
}

const userListSlice = createSlice({
	name: 'userList',
	initialState,
	reducers: {
		setInviteUserModalOpened: (
			state,
			{ payload: { isOpened } }: PayloadAction<IInviteUserModal>
		) => {
			state.isInviteUserModalOpened = isOpened
		},

		inviteUsersRequest: (
			state,
			{ payload }: PayloadAction<IInviteUserRequest>
		) => {
			state.isInviteUserLoading = true
			state.isInviteUserSuccess = false
		},

		inviteUsersSuccess: (state) => {
			state.isInviteUserLoading = false
			state.isInviteUserSuccess = true
		},

		inviteUsersFailure: (state) => {
			state.isInviteUserLoading = false
			state.isInviteUserSuccess = false
		},

		joinUserRequest: (state, { payload }: PayloadAction<IJoinUserRequest>) => {
			state.isJoinUserLoading = true
			state.isJoinUserSuccess = false
		},

		joinUserSuccess: (state) => {
			state.isJoinUserLoading = false
			state.isJoinUserSuccess = true
		},

		joinUserFailure: (state) => {
			state.isJoinUserLoading = false
			state.isJoinUserSuccess = false
		},

		getUsersRequest: (state) => {
			state.isLoading = true
		},

		getUsersSuccess: (state, { payload }: PayloadAction<ITeamRoleUser[]>) => {
			state.isLoading = false
			state.userList = payload
		},

		getUsersFailure: (state) => {
			state.isLoading = false
		},
	},
})

export const {
	setInviteUserModalOpened,

	inviteUsersRequest,
	inviteUsersSuccess,
	inviteUsersFailure,

	joinUserRequest,
	joinUserSuccess,
	joinUserFailure,

	getUsersRequest,
	getUsersSuccess,
	getUsersFailure,
} = userListSlice.actions

export const { reducer } = userListSlice
